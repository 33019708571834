.Home {
	font-family: -apple-system, 'system-ui', Roboto, 'Helvetica Neue', sans-serif !important;
	font-feature-settings: 'pnum';
	font-variant-numeric: proportional-nums;
	font-size: 14px;
	font-weight: 500;
	overflow: hidden;
}

.Home section {
	flex-wrap: wrap;
	min-height: 100vh;
	padding: 10vh 10vw;
	width: calc(100% - 20vw);
}

.Home nav {
	background: white;
	left: 0;
	position: fixed;
	top: 0;
	width: 100vw;
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 1vw 4vw;
	width: calc(100% - 8vw);
}

.Home nav .Hrefs a {
	color: inherit;
	padding: 10px;
}

.Home nav .Hrefs span:hover {
	cursor: pointer;
	text-decoration: underline;
}

.Home nav .Button {
	margin-left: 16px;
}

.Home nav .Logo {
	border: 2px solid rgb(18, 25, 44);
	background: rgb(18, 25, 44);
	border-radius: 3px;
	color: white;
	display: inline-block;
	font-size: 18px;
	font-weight: 500;
	line-height: 28px;
	margin-right: 12px;
	text-align: center;
	text-decoration: none;
	width: 28px;
}

.Home .ToSignIn {
	color: inherit;
}

.Home #Hero {
	align-items: center;
	display: flex;
	justify-content: space-evenly;
	min-height: 70vh;
	margin-bottom: 0;
}

.Home #Hero .Button {
	margin: 0 16px 0 0;
}

.Home #Hero .Actions {
	width: fit-content;
}

.Home #Hero .Title {
	color: rgb(18, 25, 44);
	font-family: 'Playfair Display', serif;
	font-size: 44px;
	font-weight: 500;
	margin-bottom: 24px;
	white-space: pre-wrap;
}

.Home section {
	align-items: center;
	display: flex;
	justify-content: space-evenly;
	min-height: 110vh;
}

.Home section .Group {
	font-size: 16px;
	font-weight: 500;
	line-height: 1.5;
	max-width: 500px;
}

.Home section .Group .Title {
	font-family: 'Playfair Display', serif;
	font-size: 44px;
	font-weight: 500;
	margin-bottom: 40px;
	white-space: pre-wrap;
}

.Home img {
	border-radius: 4px;
	box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.24);
	max-width: calc(100vw - 80px);
	width: 600px;
}

.Home #action {
	align-items: center;
	display: flex;
	justify-content: center;
	height: 200px;
	width: 100%;
}

.Home #action a {
	line-height: 40px;
	text-align: center;
	width: 300px;
}

.Home #performance {
	background: rgb(238, 239, 241);
}

.Home #view {
	background: rgb(18, 25, 44);
	color: rgb(235, 235, 235);
}

@media only screen and (max-width: 800px) {
	.Home nav .Hrefs {
		display: none;
	}
}

@media only screen and (max-width: 700px) {
	.Home section {
		flex-direction: column;
		justify-content: center;
	}

	.Home section img {
		margin-top: 40px;
	}

	.Home nav .CompanyName {
		display: none;
	}

	.Home #Hero {
		min-height: 52vh;
		margin-bottom: 12vh;
		justify-content: center;
		text-align: center;
	}

	.Home #Hero .Actions {
		margin: auto;
	}

	.Home #Hero .Title {
		font-size: 42px;
	}

	.Home #portfoliomanagement {
		flex-direction: column-reverse;
	}
}
