.Sign form .Agreement {
	border: 1px solid rgb(213, 214, 216);
	border-radius: 2px;
	display: block;
	font-weight: 500;
	line-height: 20px;
	margin: 8px 0;
	padding: 5px 10px;
	width: calc(100% - 20px - 2px);
}

.Sign form:last-child {
	margin-bottom: 16px;
}

.Sign form .Button {
	margin: 16px 0 0 0;
}
