.Settings table {
	padding-bottom: 60px;
	width: 500px;
}

.Settings table tbody tr td:first-of-type {
	min-width: 145px;
}

.Settings .PlanSignUp {
	text-transform: none;
}

.Settings .PlanSignUpBox {
	border: 1px solid lightgrey;
	line-height: 300px;
	margin-bottom: 60px;
	text-align: center;
	width: 300px;
}
