table {
	border: none;
	border-collapse: separate;
	border-spacing: 0;
	color: rgb(64, 68, 82);
	font-family: -apple-system, 'system-ui', Roboto, 'Helvetica Neue', sans-serif !important;
	font-feature-settings: 'pnum';
	font-variant-numeric: proportional-nums;
	font-size: 14px;
	font-weight: 400;
	width: 100%;
}

table caption {
	white-space: pre;
}

table caption .Flex {
	align-items: flex-end;
	display: flex;
	justify-content: space-between;
	margin-bottom: 9px;
}

table caption .Flex .Button:hover {
	text-decoration: underline;
}

table caption .Flex .Title {
	color: rgb(26, 27, 29);
	font-size: 18px;
	font-weight: 500;
	left: 0;
	padding-left: 8px;
}

table tbody tr:hover {
	background: rgb(248, 249, 251);
}

table tbody tr td,
table tfoot tr td,
table thead tr th {
	border-bottom: 1px solid rgb(237, 238, 240);
	height: 20px;
	padding: 8px;
}

table tbody tr td {
	text-transform: capitalize;
	width: 1%;
	white-space: nowrap;
}

table thead tr {
	background: rgb(255, 255, 255);
	color: rgb(26, 27, 29);
	font-size: 11px;
	font-weight: 500;
	text-align: left;
	position: sticky;
	top: -48px;
}

table thead tr th {
	font-weight: 500 !important;
}

table tfoot tr td {
	border-bottom: none;
	white-space: nowrap;
}

.ResponsiveTable {
	margin-bottom: 60px;
	/* overflow-x: auto; */
}

@media only screen and (max-width: 800px) {
	table caption .Flex {
		justify-content: flex-start;
	}
}
