.VerifyEmail {
	background: rgb(255, 255, 255);
	display: flex;
	flex-wrap: wrap;
	height: 80vh;
	left: 0;
	justify-content: center;
	padding-top: 20vh;
	position: absolute;
	top: 0;
	width: 100vw;
}

.VerifyEmail .VerifyForm {
	margin: 0 36px;
	width: 300px;
}

.VerifyEmail .VerifyForm button {
	all: initial;
	background: rgb(18, 25, 44);
	border: 1px solid rgb(18, 25, 44);
	color: rgb(248, 249, 251);
	font-family: inherit;
	font-size: 14px;
	letter-spacing: 0.5px;
	margin-top: 28px;
	padding: 8px 12px;
	transition: 0.4s;
}

.VerifyEmail .VerifyForm button:hover {
	background: rgb(255, 255, 255);
	color: rgb(48, 48, 52);
	cursor: pointer;
}

.VerifyEmail .VerifyForm .Email {
	border-bottom: 1px solid rgb(173, 174, 176);
	color: inherit;
	font-family: inherit;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0.5px;
	line-height: 36px;
	padding: 16px 8px 0 8px;
	opacity: 0.8;
	transition: 0.4s;
	width: 284px;
}

.VerifyEmail .Title {
	font-family: 'Playfair Display', serif;
	font-size: 42px;
	margin: 0 36px;
	width: 300px;
}
