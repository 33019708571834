a,
.Link {
	color: rgb(0, 102, 204);
	text-decoration: none;
}

a:hover,
.Link:hover {
	cursor: pointer;
	text-decoration: underline;
}

body {
	color: rgb(26, 27, 29);
	font-family: 'Inter', sans-serif;
	margin: 0;
}

html {
	overflow: hidden auto;
	scroll-behavior: smooth;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.AlignRight {
	text-align: right;
}

.Black {
	color: rgb(26, 27, 29);
}

.Blue {
	color: rgb(0, 102, 204);
}

.Bold {
	font-weight: 500;
}

.Break {
	width: 100%;
}

.Button {
	background: rgb(18, 25, 44);
	border: none;
	border-radius: 2px;
	color: rgb(248, 249, 251);
	cursor: pointer;
	display: inline-block;
	font-size: 14px;
	font-weight: 500;
	margin-left: 12px;
	padding: 6px 11px;
	width: fit-content;
}

.DeEmphasize {
	color: rgb(84, 90, 105);
}

.Green {
	color: darkgreen;
}

.Loading {
	background-image: -webkit-linear-gradient(left, rgb(250, 250, 250), rgb(64, 68, 82));
	background-repeat: repeat;
	background-position: 0 0;
	background-size: 100% 200px;
	font-weight: 500;
	-webkit-text-fill-color: transparent;
	-webkit-background-clip: text;
	-webkit-animation: stripes 11s ease-out infinite;
	animation: stripes 11s ease-out infinite;
}

.Red {
	color: rgb(220, 29, 18);
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.3),
	only screen and (-o-min-device-pixel-ratio: 13/10),
	only screen and (min-resolution: 120dpi) {
	body {
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
	}
}

@-webkit-keyframes stripes {
	100% {
		background-position: 0 -50px;
	}
}

@keyframes stripes {
	100% {
		background-position: 385px 0;
	}
}
