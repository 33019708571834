.EditPortfolio {
	align-items: flex-start;
	background: white;
	display: flex;
	justify-content: center;
	left: 0;
	min-height: 80vh;
	padding: 10vh 0;
	position: absolute;
	top: 0;
	width: 100vw;
	z-index: 100;
}

.EditPortfolio .HoldingFlex {
	align-items: center;
	display: flex;
	justify-content: space-between;
}

.EditPortfolio .HoldingFlex .Red:hover {
	cursor: pointer;
	text-decoration: underline;
}

.EditPortfolio .AddHolding {
	margin-top: 4px;
	width: fit-content;
}

.EditPortfolio .Holding {
	margin-bottom: 8px;
	width: 300px;
}

.EditPortfolio .Percent {
	margin-left: 8px;
	margin-bottom: 8px;
	text-align: right;
	width: 60px;
}

.EditPortfolio .DeleteHolding {
	font-weight: 500;
	position: absolute;
	right: -58px;
	line-height: 32px;
	top: 0;
	width: fit-content;
}

.EditPortfolio .DeleteHolding:hover {
	cursor: pointer;
	text-decoration: underline;
}
