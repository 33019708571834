.CoinbasePro form div {
	font-weight: 400 !important;
}

.CoinbasePro input {
	margin-bottom: 8px !important;
}

.CoinbasePro .Continue {
	margin-top: 24px !important;
}

.CoinbasePro .Title {
	font-weight: 500 !important;
	margin-bottom: 28px !important;
}
