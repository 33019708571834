.Portfolios tr:hover {
	background: white;
}

.Portfolios svg {
	display: inline-block;
	font-family: serif;
	fill: inherit;
	height: 12px;
	margin-right: 5px;
	width: 12px;
	vertical-align: middle;
}

.PortfolioTrack {
	background: rgb(248, 249, 251);
	cursor: pointer;
}

.PortfolioTrack:hover {
	background: rgb(243, 244, 246) !important;
}
