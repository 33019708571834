.Navigation {
	align-items: center;
	background: rgb(18, 25, 44);
	display: flex;
	font-family: -apple-system, 'system-ui', Roboto, 'Helvetica Neue', sans-serif !important;
	height: 50px;
	justify-content: space-between;
	left: 0;
	overflow: auto;
	padding: 0 1.75vw;
	position: fixed;
	top: 0;
	white-space: pre;
	width: calc(100vw - 3.5vw);
	z-index: 100;
}

.Navigation a {
	color: rgb(248, 249, 251);
	display: inline-block;
	font-size: 14px;
	font-weight: 500;
	height: 100%;
	padding: 16px 12px;
	vertical-align: middle;
}

.Navigation a div {
	display: inline-block;
	text-decoration: inherit;
	vertical-align: middle;
}

.Navigation .Logo {
	border: 1.5px solid rgb(248, 249, 251);
	border-radius: 4px;
	font-weight: 500;
	margin: 0 6px 0 0;
	padding: 0;
	line-height: 22px;
	text-align: center;
	width: 22px;
}

.Navigation .Profile a {
	padding: 12px 10px;
}

.Navigation .Profile a svg {
	fill: white;
	height: 13px;
	width: 13px;
	vertical-align: middle;
}
